import React, { useCallback, useRef, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import TransitionGroup from "react-transition-group/TransitionGroup";

import TextField from "./TextField";
import OptionListItem from "./OptionListItem";
import EditOptionDialog from "./EditOptionDialog";
import { sortOptions } from "../util";
import Button from "@material-ui/core/Button";
import useResizeEffect from "../hook/useResizeEffect";

const classes = theme => ({
    textField: {
        marginBottom: 16
    },
    textFieldWrapper: {
        padding: 16,
        textAlign: "center"
    },
    list: {
        padding: 16
    },
    root: {
        width: 400,
        display: "flex",
        flexDirection: "column"
    }
});

function Left({ options, handleOptionsChange, classes }) {
    const handleDeleteOption = useCallback(id => {
        handleOptionsChange(options.filter(option => option.id !== id));
    }, [ options, handleOptionsChange ]);

    const [ newOptionLabel, setNewOptionLabel ] = useState("");
    const [ newOptionWeight, setNewOptionWeight ] = useState("1");
    const handleNewOptionLabelChange = useCallback(value => {
        setNewOptionLabel(value);
    }, []);
    const handleNewOptionWeightChange = useCallback(value => {
        setNewOptionWeight(value);
    }, []);

    const newLabelInputRef = useRef();

    const handleAddNewOption = useCallback(() => {
        const nextId = options.reduce((res, option) => Math.max(res, option.id), 0) + 1;
        const newOptions = [
            ...options,
            {
                id: nextId,
                label: newOptionLabel,
                weight: parseFloat(newOptionWeight.replace(",", ".")) || 1
            }
        ].sort(sortOptions);
        handleOptionsChange(newOptions);
        setNewOptionLabel("");
        newLabelInputRef.current.focus();
    }, [ newOptionLabel, newOptionWeight, options, handleOptionsChange ]);

    const [ editOptionLabel, setEditOptionLabel ] = useState("");
    const [ editOptionWeight, setEditOptionWeight ] = useState("");
    const [ editOptionId, setEditOptionId ] = useState(null);

    const handleEditOptionLabelChange = useCallback(value => {
        setEditOptionLabel(value);
    }, []);

    const handleEditOptionWeightChange = useCallback(value => {
        setEditOptionWeight(value);
    }, []);

    const handleEditOptionSave = useCallback(() => {
        setEditOptionId(0);
        const option = options.find(option => option.id === editOptionId);
        const newOptions = [
            ...(options.filter(option => option.id !== editOptionId)),
            {
                ...option,
                label: editOptionLabel,
                weight: parseFloat(editOptionWeight.toString().replace(",", ".")) || 1
            }
        ].sort(sortOptions);
        handleOptionsChange(newOptions);
    }, [ editOptionLabel, editOptionId, editOptionWeight, options, handleOptionsChange ]);

    const handleOpenEditOptionDialog = useCallback(id => {
        setEditOptionId(id);
        const option = options.find(option => option.id === id);
        setEditOptionLabel(option.label);
        setEditOptionWeight(option.weight);
    }, [options]);

    const handleCloseEditOptionDialog = useCallback(() => {
        setEditOptionId(0);
    }, []);

    const handleWeightBlur = useCallback(() => {
        handleNewOptionWeightChange((parseFloat(newOptionWeight) || 1).toString());
    }, [ newOptionWeight, handleNewOptionWeightChange ]);

    const createListStyle = useCallback(() => {
        if (window.innerHeight > 400) {
            return {
                overflowY: "auto"
            }
        } else {
            return null;
        }
    }, []);
    const [ listStyle, setListStyle ] = useState(createListStyle());

    const handleResize = useCallback(() => {
        setListStyle(createListStyle());
    }, [ createListStyle ]);
    useResizeEffect(handleResize);

    return (
        [
            <EditOptionDialog open={!!editOptionId}
                              onClose={handleCloseEditOptionDialog}
                              label={editOptionLabel}
                              weight={editOptionWeight}
                              onCloseAndSave={handleEditOptionSave}
                              onLabelChange={handleEditOptionLabelChange} key="1"
                              onWeightChange={handleEditOptionWeightChange}/>,
            <div className={classes.textFieldWrapper} key="2">
                <TextField className={classes.textField}
                           inputRef={newLabelInputRef}
                           onEnterKeyPress={handleAddNewOption}
                           label="Label"
                           value={newOptionLabel}
                           onChange={handleNewOptionLabelChange}/>
                <TextField onBlur={handleWeightBlur}
                           className={classes.textField}
                           onEnterKeyPress={handleAddNewOption}
                           label="Weight"
                           value={newOptionWeight}
                           onChange={handleNewOptionWeightChange}/>
                <Button color="primary"
                        disabled={!newOptionLabel || !newOptionWeight}
                        onClick={handleAddNewOption}>
                    Add
                </Button>
            </div>,
            <List disablePadding dense style={listStyle} className={classes.list} key="3">
                <TransitionGroup>
                    {options.map(option =>
                        <Collapse key={option.id}>
                            <OptionListItem value={option.id}
                                            onDelete={handleDeleteOption}
                                            onEdit={handleOpenEditOptionDialog}
                                            primaryLabel={option.label}
                                            secondaryLabel={`x${option.weight}`}/>
                        </Collapse>
                    )}
                </TransitionGroup>
            </List>
    ]
    )
}

export default withStyles(classes)(React.memo(Left));
