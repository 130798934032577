import { useEffect, useRef } from "react";

import useMountedRef from "./useMountedRef";

function useMountEffect(fn) {
    const mountedRef = useMountedRef();
    const hasRun = useRef(false);
    useEffect(() => {
        if (!hasRun.current && mountedRef.current) {
            hasRun.current = true;
            fn();
        }
    })
}

export default useMountEffect;
