import React from "react";

function Pointer({ className }) {
    return (
        <svg height="20" width="40" className={className}>
            <polygon points="0,10 40,0 40,20" style={{ fill: "black" }} />
        </svg>
    )
}

export default Pointer;
