import React, { useMemo } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const classes = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "white"
    },
    modalFade: {
        outline: "none"
    },
    backdrop: {
        backgroundColor: "rgba(1, 1, 1, 0.8)"
    }
};

function WinnerModal({ open, text, onClose, classes }) {
    const backdropProps = useMemo(() => ({
        timeout: 500,
        className: classes.backdrop
    }), [ classes.backdrop ]);

    return (
        <Modal className={classes.modal}
               open={open}
               onClose={onClose}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={backdropProps}>
            <Fade in={open} className={classes.modalFade}>
                <Typography variant="h1">
                    {text}
                </Typography>
            </Fade>
        </Modal>
    );
}

export default withStyles(classes)(React.memo(WinnerModal));
