import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "::selection": {
                    background: "#e88e14",
                    color: "#fff"
                }
            },
        },
    },
    palette: {
        primary: {
            main: "#e88e14"
        }
    }
});

console.log(theme);

export default theme;
