import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";

import TextField from "./TextField";

function EditOptionDialog({ open, label, weight, onClose, onCloseAndSave, onLabelChange, onWeightChange }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Edit option</DialogTitle>
            <DialogContent>
                <TextField onEnterKeyPress={onCloseAndSave} autoFocus value={label} onChange={onLabelChange} label="Label"/>
            </DialogContent>
            <DialogContent>
                <TextField onEnterKeyPress={onCloseAndSave} value={weight} onChange={onWeightChange} label="Weight"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseAndSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(EditOptionDialog);
