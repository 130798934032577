import React, { useCallback, useMemo } from "react";

import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
    amber,
    blue,
    cyan,
    deepOrange,
    deepPurple,
    green, indigo, lightBlue, lightGreen,
    lime,
    orange,
    pink,
    purple,
    red, teal,
    yellow
} from "@material-ui/core/colors";

import useRotation from "../hook/useRotation";
import Arc from "./Arc";
import Pointer from "./Pointer";

const classes = {
    root: {
        position: "relative"
    },
    paperContent: {
        overflow: "hidden",
    },
    arc: {
        position: "absolute",
        top: 0,
        left: 0
    },
    pointer: {
        position: "absolute",
        right: -10,
        top: "calc(50% - 10px)"
    }
};

const COLORS = [ red[300], blue[300], green[300], yellow[300], purple[300], cyan[300], orange[300], pink[300], lime[300], deepPurple[300], deepOrange[300], indigo[300], lightBlue[300], teal[300], lightGreen[300], amber[300] ];

function shuffle(arr) {
    const res = [ ...arr ];
    for (let i = res.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [res[i], res[j]] = [res[j], res[i]];
    }
    return res;
}

function Wheel({ options, size, push, classes, handleSelectWinner }) {
    const handleStop = useCallback(deg => {
        const totalWeight = options.reduce((res, option) => res + option.weight, 0);
        let usedWeight = 0;
        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            usedWeight += option.weight;
            if (360 - deg < usedWeight / totalWeight * 360) {
                handleSelectWinner && handleSelectWinner(option.id);
                break;
            }
        }
    }, [ handleSelectWinner, options ]);

    const deg = useRotation({ push, onStop: handleStop });
    const paperStyle = useMemo(() => ({
        width: size,
        height: size,
        borderRadius: size / 2,
    }), [ size ]);

    const arcsWrapperStyle = useMemo(() => ({
        width: size,
        height: size,
        borderRadius: size / 2,
        transform: `rotate(${deg}deg)`
    }), [size, deg]);

    const colors = useMemo(() => {
        const randomColors = shuffle(COLORS);
        return options.reduce((res, option, i) => {
            res.push(randomColors[i%randomColors.length]);
            return res;
        }, []);
    }, [ options ]);

    const arcs = useMemo(() => {
        const totalWeight = options.reduce((res, option) => res + option.weight, 0);
        let usedWeight = 0;
        return options.map((option, i) => {
            const prevAngle = usedWeight / totalWeight * 360;
            usedWeight += option.weight;
            return (
                <Arc className={classes.arc}
                     label={option.label}
                     key={option.id}
                     color={colors[i]}
                     radius={size / 2}
                     startAngle={prevAngle}
                     endAngle={prevAngle + option.weight / totalWeight * 360}/>
            )
        })
    }, [ options, classes.arc, size, colors ]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} style={paperStyle} elevation={5}>
                <div className={classes.paperContent}>
                    <div style={arcsWrapperStyle}>
                        {arcs}
                    </div>
                </div>
            </Paper>
            <Pointer className={classes.pointer}/>
        </div>
    );
}

export default withStyles(classes)(React.memo(Wheel));
