import React, { useRef, useState, useCallback } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

import Wheel from "../component/Wheel";
import WinnerModal from "../component/WinnerModal";
import useFavicon from "../hook/useFavicon";
import useTitle from "../hook/useTitle";
import { sortOptions } from "../util";
import useResizeEffect from "../hook/useResizeEffect";
import Hidden from "@material-ui/core/Hidden";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
import StaticLeft from "../component/StaticLeft";
import DynamicLeft from "../component/DynamicLeft";



// function loadJsonFromLocalStorage(key) {
//     const item = localStorage.getItem("key");
//     return item ? JSON.parse(item) : null;
// }

const DEFAULT_OPTIONS = [
    { label: "Option 1", weight: 1, id: 1 },
    { label: "Option 2", weight: 1, id: 2 },
    { label: "Option 3", weight: 1, id: 3 }
];

function SpinTheWheel({ classes }) {
    useFavicon("spinthewheel_favicon.png");
    useTitle("Spin the wheel!");

    const [ options, setOptions ] = useState(() => {
        return DEFAULT_OPTIONS.sort(sortOptions);
    });

    const [ push, setPush ] = useState(0);
    const stopTimeoutRef = useRef();

    const [ running, setRunning ] = useState(false);

    const handleStart = useCallback(() => {
        clearTimeout(stopTimeoutRef.current);
        setPush(1000);
        setRunning(true);
    }, []);

    const handleStop = useCallback(() => {
        stopTimeoutRef.current = setTimeout(() => setPush(0), Math.random() * 1000);
        setRunning(false);
    }, []);

    const createLeftStyle = useCallback(() => {
        if (window.innerHeight > 400) {
            return {
                height: window.innerHeight
            }
        } else {
            return {
                height: window.innerHeight,
                overflowY: "auto"
            }
        }
    }, []);
    const [ leftStyle, setLeftStyle ] = useState(createLeftStyle());

    const [ size, setSize ] = useState(800);
    const handleResize = useCallback(() => {
        setSize(Math.min(window.innerHeight - 110, window.innerWidth - 40));
        setLeftStyle(createLeftStyle());
    }, [ createLeftStyle ]);
    useResizeEffect(handleResize);

    const [ winner, setWinner ] = useState(null);

    const handleCloseWinnerModal = useCallback(() => {
        setWinner(null);
    }, []);

    const handleSelectWinner = useCallback(id => {
        const option = options.find(option => option.id === id);
        setWinner(option.label);
    }, [ options ]);

    const [ open, setOpen ] = useState(false);
    const handleOpenDrawer = useCallback(() => {
        setOpen(true);
    }, []);
    const handleCloseDrawer = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <div className={classes.root}>
            <WinnerModal open={!!winner} text={winner} onClose={handleCloseWinnerModal}/>
            <Hidden mdUp>
                <Fab className={classes.fab} size="small" onClick={handleOpenDrawer}>
                    <MenuIcon/>
                </Fab>
                <DynamicLeft className={classes.left} style={leftStyle} options={options} handleOptionsChange={setOptions} open={open} handleClose={handleCloseDrawer}/>
            </Hidden>
            <Hidden smDown>
                <StaticLeft className={classes.left} style={leftStyle} options={options} handleOptionsChange={setOptions}/>
            </Hidden>
            <div className={classes.right}>
                <Wheel handleSelectWinner={handleSelectWinner}
                       options={options}
                       size={size}
                       push={push}/>
                <Button className={classes.startStopButton}
                        color="primary"
                        variant="contained"
                        onClick={running ? handleStop : handleStart}>{running ? "Stop" : "Start"}</Button>
            </div>
        </div>
    );
}

const classes = theme => ({
    root: {
        display: "flex"
    },
    right: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        marginTop: 20,
        flexGrow: 1
    },
    startStopButton: {
        margin: 20,
        color: "white",
        width: 100,
        height: 50
    },
    fab: {
        position: "fixed",
        left: 10,
        top: 10,
        zIndex: theme.zIndex.drawer - 1
    },
    left: {
        width: 400,
        display: "flex",
        flexDirection: "column"
    }
});

export default withStyles(classes)(SpinTheWheel);
