import { useRef, useState } from "react";

import useInterval from "./useInterval";

function useRotation({tick = 100, push = 0, k = 1, speedLimit = 1, onStop}) {
    const [ deg, setDeg ] = useState(0);

    const timout = 1000/tick;

    const speedRef = useRef(0);
    const hasStoppedRef = useRef(true);
    useInterval(() => {
        speedRef.current = speedRef.current + (push - speedRef.current * k) / tick;
        if (Math.abs(speedRef.current) > speedLimit) {
            hasStoppedRef.current = false;
            setDeg(prev =>  (prev + speedRef.current / tick) % 360);
        } else if (!hasStoppedRef.current) {
            hasStoppedRef.current = true;
            onStop && onStop(deg);
        }
    }, timout);

    return deg;
}

export default useRotation;
