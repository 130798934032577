import { useCallback, useEffect, useRef } from "react";

function useEnterKeyPressHandler(callback) {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    });

    return useCallback(e => {
        if(e.key === "Enter"){
            callbackRef.current();
        }
    }, []);
}

export default useEnterKeyPressHandler;
