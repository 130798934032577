import React, { useMemo } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import { Link as RouterLink } from "react-router-dom";
import { Link as MuiLink } from "@material-ui/core";
import clsx from "clsx";

import Bonemash from "../component/Bonemash";
import SpinTheWheelLogo from "../component/SpinTheWheelLogo";
import LmxLogo from "../component/LmxLogo";
import OreventLogo from "../component/OreventLogo";

const classes = theme => ({
    root: {
        maxWidth: 1000,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        padding: "0 20px 100px 20px"
    },
    bonemash: {
        [theme.breakpoints.up("sm")]: {
            padding: "60px 20px 0 20px"
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: 60
        }
    },
    subtitle: {
        fontWeight: 300,
        fontSize: 24,
        textAlign: "center",
        [theme.breakpoints.up("sm")]: {
            fontSize: 24
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 20
        }
    },
    header: {
        fontWeight: 300,
        fontSize: 32,
        marginTop: 30,
        marginBottom: 10
    },
    portfolioTitle: {
        fontWeight: 300,
        fontSize: 20,
        textAlign: "center"
    },
    spinTheWheelLogo: {

    },
    logo: {
        [theme.breakpoints.up("sm")]: {
            width: 100,
            height: 100,
            padding: 16
        },
        [theme.breakpoints.down("xs")]: {
            width: 200,
            height: 200,
            padding: 24
        }
    },
    lmxLogo: {
        backgroundColor: "#212121"
    },
    oreventLogo: {
        backgroundColor: "#c60c30"
    },
    logoSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    portfolios: {
        display: "flex",
        justifyContent: "space-evenly",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    },
    text: {
        fontWeight: 300,
        fontSize: 20,
    },
    ul: {
        marginTop: 0,
        marginBottom: 0
    },
    john: {
        width: "100%",
        maxWidth: 500,
        flexShrink: 1,
        borderRadius: "50%",
        alignSelf: "center",
        margin: "10px 0",
        filter: "drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 4px)",
    }
});

function Home({ classes }) {
    const showWheel = useMemo(() => {
        return localStorage.getItem("wheel") != null;
    }, []);

    return (
        <main className={classes.root}>
            <h1><Bonemash className={classes.bonemash}/></h1>
            <h2 className={classes.subtitle}>Fullstack webbutveckling i Malmö</h2>
            <h2 className={classes.header}>Om Bonemash</h2>
            <p className={classes.text}>
                Jag heter John Lovén och driver företaget Bonemash AB i Malmö som specialiserar sig på att skapa hemsidor. Företaget grundades 2017 för att bygga den digitala skolplattformen <MuiLink href="https://lmx.nu/">LMX</MuiLink>. Plattformen har snabbt blivit väldigt populär och används redan av tusentals lärare över hela Norden. Sedan 2021 jobbar jag även som konsult inom webbutveckling.
            </p>
            <img src="/john.webp" className={classes.john} alt="John Lovén"/>
            <h2 className={classes.header}>Portfolio</h2>
            <div className={classes.portfolios}>
                <div className={classes.logoSection}>
                    <a href="https://lmx.nu/" aria-label="LMX">
                        <Paper className={clsx([ classes.logo, classes.lmxLogo ])}>
                            <LmxLogo/>
                        </Paper>
                    </a>
                    <h3 className={classes.portfolioTitle}>LMX</h3>
                </div>
                <div className={classes.logoSection}>
                    <a href="https://xn--revent-9xa.se/" aria-label="Ørevent">
                        <Paper className={clsx([ classes.logo, classes.oreventLogo ])}>
                            <OreventLogo/>
                        </Paper>
                    </a>
                    <h3 className={classes.portfolioTitle}>Ørevent</h3>
                </div>
                {!showWheel ? null :
                    <div className={classes.logoSection}>
                        <RouterLink to="/spinthewheel">
                            <Paper className={clsx([ classes.logo, classes.spinTheWheelLogo ])}>
                                <SpinTheWheelLogo/>
                            </Paper>
                        </RouterLink>
                        <h2 className={classes.portfolioTitle}>Spin the wheel!</h2>
                    </div>
                }
            </div>
            <h2 className={classes.header}>Tech stack</h2>
            <ul className={classes.text}>
                <li>React.js</li>
                <li>MySQL</li>
                <li>Spring Boot</li>
                <li>Nginx</li>
                <li>Java</li>
                <li>Node.js</li>
                <li>HTML5</li>
                <li>Javascript</li>
                <li>Elastic Stack</li>
                <li>CSS3</li>
                <li>Ubuntu</li>
                <li>Apollo GraphQL</li>
                <li>Jenkins</li>
            </ul>
            <h2 className={classes.header}>Kontakt</h2>
            <p className={classes.text}>
                Om du vill komma i kontakt med mig är du välkommen att skicka ett mejl till <MuiLink href="mailto:john.loven@lmx.nu">john.loven@lmx.nu</MuiLink>.
            </p>
        </main>
    );
}

export default withStyles(classes)(Home);
