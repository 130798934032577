import React, { useCallback } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core";
import clsx from "clsx";

const classes = {
    root: {
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0
    },
    clickable: {
        cursor: "pointer"
    },
    listItemSecondaryAction: {
        height: "100%"
    }
};

function OptionListItem({ primaryLabel, secondaryLabel, value, onDelete, onEdit, classes, className }) {
    const handleClickDelete = useCallback(() => {
        onDelete(value)
    }, [ onDelete, value ]);

    const handleEditClick = useCallback(() => {
        onEdit && onEdit(value);
    }, [ onEdit, value ]);

    return (
        <ListItem className={clsx([ classes.root, className ])}>
            <ListItemText onClick={handleEditClick} className={onEdit ? classes.clickable : null} primary={primaryLabel} secondary={secondaryLabel}/>
            <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                <IconButton edge="end" onClick={handleClickDelete}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default withStyles(classes)(React.memo(OptionListItem));
