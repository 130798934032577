import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";

import Layout from "./Layout";
import theme from "./theme";

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <Layout/>
        </MuiThemeProvider>
    )
}

export default App;
