import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import SpinTheWheel from "./page/SpinTheWheel";
import Home from "./page/Home";

function Layout() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/spinthewheel" render={routeProps => <SpinTheWheel {...routeProps} />}/>
                <Route exact path="/" render={routeProps => <Home {...routeProps} />}/>
                <Redirect to="/" />
            </Switch>
        </BrowserRouter>
    );
}

export default Layout;
