import React from "react";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";

import Left from "./Left";

function DynamicLeft({ className, style, options, handleOptionsChange, open, handleClose }) {

    return (
        <Drawer open={open} onClose={handleClose}>
            <Paper square style={style} className={className}>
                <Button onClick={handleClose}>Close</Button>
                <Left options={options} handleOptionsChange={handleOptionsChange}/>
            </Paper>
        </Drawer>
    )
}

export default React.memo(DynamicLeft);
