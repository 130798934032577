import { useEffect } from "react";

function useFavicon(url) {
    useEffect(() => {
        document.getElementById("favicon").href = url;
        document.getElementById("shortcut").href = url;
    }, [ url ]);
}

export default useFavicon;
