import React, { useMemo } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = angleInDegrees * Math.PI / 180.0;
    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

function createD(x, y, radius, startAngle, endAngle){
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    return [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
        "L", x, y,
        "Z"
    ].join(" ");
}

const classes = {
    relative: {
        position: "relative"
    },
    label: {
        transformOrigin: `0 50%`,
        position: "absolute",
        height: 20,
        lineHeight: "normal",
        textAlign: "center",
    }
};

function Arc({ radius, startAngle, endAngle, color, className, classes, label }) {
    const labelStyle = useMemo(() => {
        return {
            transform: `rotate(${(startAngle + endAngle) / 2}deg)`,
            top: radius - 10,
            left: radius,
            width: radius
        }
    }, [ startAngle, endAngle, radius ]);

    return (
        <div className={className}>
            <div className={classes.relative}>
                <svg viewBox={`-2 -2 ${radius * 2 + 4} ${radius * 2 + 4}`} width={radius * 2} height={radius * 2}>
                    <path d={createD(radius, radius, radius, startAngle, endAngle)} fill={color} stroke="black" strokeWidth={2}/>
                </svg>
                <Typography className={classes.label} style={labelStyle}>{label}</Typography>
            </div>
        </div>
    )
}

export default withStyles(classes)(React.memo(Arc));
