import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

const classes = {
    root: {
        width: "100%"
    },
    orange: {
        fill: "#e88e14"
    },
    grey: {
        fill: "#7e7e7e"
    }
};

function Bonemash({ classes, className }) {
    return (
        <svg aria-labelledby="bonemash-title" viewBox="0 0 2474.0137 414.00549" className={clsx([ classes.root, className ])}>
            <title id="bonemash-title">Bonemash</title>
            <g id="layer1" transform="matrix(1 0 0 1.0027 -.0057211 -641.14)">
                <g id="g4285" className={classes.orange} transform="matrix(.81915 .57358 -.57358 .81915 519.24 6.7731)">
                    <path id="path4210" d="m160 1052.4-0.00002-52.5a130 227.5 0 0 1 -12.978 -1.1382 130 227.5 0 0 1 -12.848 -3.3974 130 227.5 0 0 1 -12.592 -5.626 130 227.5 0 0 1 -12.207 -7.7998 130 227.5 0 0 1 -11.706 -9.93 130 227.5 0 0 1 -11.078 -11.89 130 227.5 0 0 1 -10.344 -13.76 130 227.5 0 0 1 -9.508 -15.5 130 227.5 0 0 1 -8.576 -17.08 130 227.5 0 0 1 -7.559 -18.5 130 227.5 0 0 1 -6.466 -19.73 130 227.5 0 0 1 -5.309 -20.75 130 227.5 0 0 1 -4.096 -21.58 130 227.5 0 0 1 -2.847 -22.19 130 227.5 0 0 1 -1.565 -22.58 130 227.5 0 0 1 -0.326 -16.09h-30a160 280 0 0 0 160 280z"/>
                    <path id="path4212" d="m160 772.36v52.5a130 227.5 0 0 1 12.978 1.1382 130 227.5 0 0 1 12.848 3.3974 130 227.5 0 0 1 12.592 5.626 130 227.5 0 0 1 12.207 7.7998 130 227.5 0 0 1 11.701 9.8916 130 227.5 0 0 1 11.078 11.884 130 227.5 0 0 1 10.344 13.764 130 227.5 0 0 1 9.5078 15.5 130 227.5 0 0 1 8.5761 17.083 130 227.5 0 0 1 7.5586 18.498 130 227.5 0 0 1 6.4668 19.725 130 227.5 0 0 1 5.3086 20.757 130 227.5 0 0 1 4.0957 21.581 130 227.5 0 0 1 2.8477 22.186 130 227.5 0 0 1 1.5644 22.576 130 227.5 0 0 1 0.32624 16.092h30a160 280 0 0 0 -160 -280z"/>
                </g>
                <g id="g7">
                    <g id="text4356" className={classes.grey} transform="scale(.99582 1.0042)">
                        <path id="path4155" className={classes.grey} d="m413.12 934.14q-21.142 0-35.237-2.9109-13.941-2.7577-22.827-5.9749-8.8858-3.3705-13.482-10.418-4.5961-7.2005-6.8941-11.184v27.117h-29.415v-235.32h29.415v75.529q1.9916-3.2173 6.5877-6.8941 4.7493-3.8301 13.635-7.0473 9.039-3.3705 23.134-5.5153t35.084-2.1448q29.415 0 49.944 4.4429t33.398 15.167 18.691 28.802q5.8217 17.925 5.8217 45.042 0 26.657-5.8217 44.276-5.8217 17.618-18.691 28.036t-33.398 14.708q-20.529 4.2897-49.944 4.2897zm0-22.98q17.159 0 29.568-1.0724 12.563-1.0724 21.295-3.8301 8.7326-2.7577 14.095-7.6602 5.5153-4.9025 8.4262-12.409 3.0641-7.6602 3.9833-18.231 1.0724-10.724 1.0724-25.125t-1.0724-25.125q-0.91922-10.724-3.9833-18.231-2.9109-7.6602-8.4262-12.563-5.3621-5.0557-14.095-7.8134-8.7326-2.9109-21.295-3.9833-12.409-1.0724-29.568-1.0724-14.708 0-26.198 0.76601-11.49 0.61281-20.223 3.0641-8.7326 2.4513-14.861 7.0474-5.9749 4.5961-9.805 12.256-3.8301 7.6602-5.6685 18.997-1.6852 11.337-1.6852 27.117 0 15.627 1.6852 26.811 1.8384 11.031 5.6685 18.538t9.805 12.103q6.1281 4.4429 14.861 6.7409 8.7326 2.298 20.223 3.0641 11.49 0.61281 26.198 0.61281z"/>
                        <path id="path4157" className={classes.grey} d="m550.39 841.3q0-27.117 5.8217-44.889t18.691-28.189q12.869-10.418 33.398-14.554 20.529-4.2897 49.944-4.2897t49.944 4.2897q20.529 4.1365 33.398 14.554t18.691 28.189q5.8217 17.772 5.8217 44.889t-5.8217 45.042-18.691 28.496-33.398 15.014q-20.529 4.2897-49.944 4.2897t-49.944-4.2897q-20.529-4.4429-33.398-15.014t-18.691-28.496q-5.8217-17.925-5.8217-45.042zm29.415 0q0 14.708 1.0724 25.585t3.9833 18.691q3.0641 7.8134 8.4262 12.716 5.3621 4.9025 14.095 7.8134 8.7326 2.9109 21.142 3.9833 12.563 1.0724 29.721 1.0724 14.708 0 26.198-0.61281 11.643-0.76602 20.223-3.2173 8.7326-2.4512 14.861-6.8941 6.1281-4.5961 9.805-12.409 3.8301-7.8134 5.5153-19.15 1.8384-11.49 1.8384-27.577 0-15.78-1.8384-26.811-1.6852-11.184-5.5153-18.691-3.6769-7.507-9.805-11.797-6.1281-4.4429-14.861-6.5877-8.5794-2.298-20.223-2.9109-11.49-0.61282-26.198-0.61282t-26.351 0.61282q-11.49 0.61281-20.223 2.9109-8.5794 2.1448-14.708 6.5877-6.1281 4.2897-9.9582 11.797-3.6769 7.507-5.5153 18.691-1.6852 11.031-1.6852 26.811z"/>
                        <path id="path4159" className={classes.grey} d="m976.9 930.77v-98.05q0-16.393-2.4512-27.577-2.4512-11.337-9.805-18.231-7.3538-6.8941-20.836-9.9582t-35.543-3.0641q-23.9 0-39.22 3.5237-15.167 3.5237-23.9 9.9582-8.7326 6.4345-12.103 15.474-3.2173 9.039-3.2173 20.07v107.86h-29.415v-176.49h29.415v29.415q3.2173-7.507 9.1922-12.869 5.9749-5.5153 13.482-9.3454 7.507-3.8301 16.24-6.1281 8.7326-2.4512 17.465-3.6769 8.7326-1.3788 16.852-1.8384 8.273-0.45961 15.014-0.45961 19.61 0 33.705 2.4512 14.248 2.4512 24.053 7.2006 9.9582 4.7493 15.933 11.797 5.9749 7.0473 9.1922 16.393 3.3705 9.1922 4.4429 20.682 1.0724 11.337 1.0724 24.819l-0.1532 98.05h-29.415z"/>
                        <path id="path4161" className={classes.grey} d="m1148.5 934.14q-29.415 0-49.944-3.9833-20.529-4.1365-33.398-14.095-12.869-9.9582-18.691-26.657-5.8217-16.699-5.8217-41.978 0-28.955 5.8217-47.799 5.8218-18.997 18.691-30.181t33.398-15.627q20.529-4.4429 49.944-4.4429 20.989 0 36.309 3.0641 15.474 3.0641 26.351 8.8858 11.031 5.8217 17.772 14.095 6.8941 8.273 10.724 17.465 3.9833 9.039 5.3621 21.295 1.532 12.256 1.532 25.891l-2.4512 9.805h-174.04q0 20.223 3.9833 33.245 3.9833 12.869 13.176 18.844 9.1922 5.9749 24.206 7.6602 15.014 1.532 37.075 1.532 20.223 0 33.398-1.0724 13.329-1.2256 21.142-4.4429 7.8134-3.2173 10.878-8.8858 3.2172-5.8217 3.2172-15.014h29.415q0 14.248-5.0557 24.359-4.9025 9.9582-16.393 16.24-11.49 6.1281-30.334 8.8858-18.691 2.9109-46.267 2.9109zm0-160.25q-14.708 0-26.351 0.61282-11.49 0.61281-20.223 2.7576-8.5794 1.9916-14.708 5.9749-6.1282 3.8301-9.9582 9.1922-3.6769 5.2089-5.5153 14.861-1.6853 9.4986-1.6853 22.98h147.08q0-17.925-3.8301-28.036-3.83-10.265-12.103-16.852-8.273-6.5877-21.295-9.039-13.022-2.4512-31.407-2.4512z"/>
                        <path id="path4163" className={classes.grey} d="m1472.1 930.77h-29.415v-99.582q0-15.933-2.1449-26.811-1.9916-11.031-8.4261-17.772-6.2814-6.7409-17.925-9.6518-11.49-3.0641-30.334-3.0641-20.682 0-34.011 3.5237-13.329 3.3705-20.989 9.6518-7.6602 6.1281-10.724 14.861-2.9108 8.7326-2.9108 19.457v109.39h-29.415v-176.49h29.415v27.883q0.1532-0.45961 1.3788-3.0641 1.2256-2.6044 4.2897-6.1281 3.2173-3.6769 8.5794-7.8134 5.5153-4.1365 14.095-7.6602 8.7325-3.5237 20.989-5.8217t29.109-2.298q16.546 0 28.649 2.1448 12.256 1.9916 20.836 6.1281 8.7326 3.9833 14.095 10.111 5.5153 5.9749 8.7326 13.788 2.6044-4.1365 8.1197-9.6518t15.32-10.571q9.9583-5.0557 24.819-8.4262 14.861-3.5237 36.309-3.5237 17.618 0 30.181 2.4512 12.716 2.298 21.448 7.0474 8.7325 4.5961 13.941 11.49 5.3621 6.8941 8.1198 16.086 2.9109 9.039 3.8301 20.376 0.9192 11.184 0.9192 24.359v99.582h-29.415v-99.582q0-15.933-2.1448-26.811-1.9917-11.031-8.4262-17.772-6.2813-6.7409-17.925-9.6518-11.49-3.0641-30.334-3.0641-20.682 0-34.011 3.5237-13.329 3.3705-20.989 9.6518-7.6602 6.1281-10.724 14.861-2.9109 8.7326-2.9109 19.457v109.39z"/>
                        <path id="path4165" className={classes.grey} d="m1843.9 906.56q-0.4596 1.8384-6.8941 9.1922-4.5961 5.5153-12.256 9.805-7.6602 4.1365-20.529 6.4345-12.716 2.4512-33.552 2.4512-27.73 0-47.34-2.9109-19.61-2.7576-32.019-8.5794-12.41-5.9749-18.231-15.167-5.6685-9.1922-5.6685-21.908 0-13.329 3.0641-22.521 3.2172-9.3454 9.1921-15.627 6.1282-6.2813 14.708-9.805 8.5794-3.5237 19.457-5.3621 11.031-1.8384 24.053-2.298 13.022-0.45961 27.73-0.45961 20.989 0 35.084 1.8384 14.095 1.6852 22.98 4.4429 9.039 2.6045 13.635 5.6685 4.5961 3.0641 6.5877 5.6685 0-17.159-1.532-29.262-1.3789-12.103-4.7493-20.223-3.2173-8.1198-8.4262-12.869-5.2089-4.9025-12.869-7.3538-7.5069-2.4512-17.618-3.0641-10.111-0.76602-23.134-0.76602-20.223 0-33.552 1.0724-13.329 0.91921-21.142 3.8301-7.8134 2.9109-11.031 8.1198-3.2173 5.0557-3.2173 13.329h-29.415q0-13.941 4.9025-23.593 5.0557-9.6518 16.546-15.627 11.644-6.1281 30.488-8.8858t46.42-2.7577q26.964 0 45.654 4.4429 18.691 4.4429 30.181 15.627 11.643 11.184 16.699 30.181 5.2089 18.844 5.2089 47.799v83.342h-29.415v-24.206zm-68.329 4.5961q19.457 0 32.479-1.8384 13.175-1.8384 21.142-5.5153 7.9666-3.6769 11.337-9.1922 3.3705-5.5153 3.3705-12.869 0-7.3538-3.9833-12.409-3.8301-5.2089-13.022-8.4262-9.0389-3.2173-24.053-4.5961-15.014-1.532-37.075-1.532t-35.696 0.76601q-13.482 0.76602-20.836 3.5237-7.3537 2.7577-9.9582 8.1198-2.4512 5.3621-2.4512 14.554 0 9.1922 2.6044 15.014 2.7577 5.6685 11.031 8.8858 8.2729 3.2173 23.746 4.4429 15.627 1.0724 41.365 1.0724z"/>
                        <path id="path4167" className={classes.grey} d="m2015.6 850.33q17.159 0 29.262 0.61281 12.103 0.45961 20.223 1.8384 8.273 1.2256 13.022 3.2173 4.7493 1.9916 7.2005 5.9749 2.4513 3.8301 3.0641 7.8134 0.766 3.8301 0.766 8.8858 0 5.5153-0.766 10.111-0.6128 4.4429-3.2173 8.1198-2.6044 3.6769-7.6601 6.2813t-13.788 4.4429q-8.7326 1.8384-21.755 2.7576-12.869 0.76602-31.254 0.76602-20.223 0-33.552-1.0724-13.175-1.2256-20.989-4.4429-7.8134-3.2173-11.031-8.8858-3.0641-5.8217-3.0641-15.014h-29.415q0 13.941 4.9025 23.593 5.0557 9.6518 16.546 15.78 11.49 5.9749 30.181 8.7326 18.844 2.7577 46.42 2.7577 22.061 0 38.607-1.3788 16.699-1.2256 28.649-3.8301 11.95-2.7577 19.763-7.0473 7.9666-4.4429 12.563-10.418 4.596-6.1281 6.4345-13.941 1.8384-7.9666 1.8384-17.925 0-9.9582-1.8384-17.465-1.8385-7.6602-6.4345-13.175-4.4429-5.6685-11.95-10.418-7.5069-4.7493-18.997-7.0473t-27.27-3.2173q-15.627-0.91922-36.462-0.91922-17.159 0-29.262 0-12.103-0.1532-20.376-0.91921-8.1198-0.91922-12.869-2.6045-4.7493-1.6852-7.2005-4.9025-2.4513-3.2173-3.2173-8.1198-0.6128-5.0557-0.6128-10.265 0-8.8858 3.9833-13.941 3.9833-5.0557 13.176-7.507 9.1922-2.4512 24.206-3.0641 15.014-0.61282 37.075-0.61282 20.223 0 33.398 1.2256 13.329 1.0724 21.142 4.1365 7.8134 2.9109 10.878 8.1198 3.2172 5.2089 3.2172 13.482h29.415q0-13.329-4.4429-22.98-4.4429-9.6518-15.627-15.933-11.031-6.4345-30.028-9.4986-18.844-3.0641-47.953-3.0641-29.415 0-49.944 2.6045-20.529 2.6044-33.398 8.5794-12.869 5.8217-18.691 15.32-5.8217 9.3454-5.8217 23.134 0 10.111 1.8385 18.844 1.8384 8.5794 6.2813 14.554 4.4429 5.8217 11.95 9.3454 7.6602 3.5237 18.997 5.5153 11.49 1.8384 27.27 2.4512 15.78 0.61282 36.616 0.61282z"/>
                        <path id="path4169" className={classes.grey} d="m2334.3 930.77v-99.582q0-15.933-2.4512-26.811-2.4513-11.031-9.805-17.772-7.3538-6.7409-20.836-9.6518-13.482-3.0641-35.543-3.0641-23.9 0-39.22 3.5237-15.167 3.3705-23.9 9.6518-8.7325 6.1281-12.103 14.861-3.2173 8.7326-3.2173 19.457v109.39h-29.415v-235.32h29.415v86.713q3.0641-7.2005 8.7326-12.409 5.6685-5.2089 13.022-8.8858 7.5069-3.6769 16.086-5.8217 8.5794-2.298 17.312-3.5237 8.8858-1.2256 17.312-1.6852 8.5794-0.45961 15.78-0.45961 19.61 0 33.705 2.4512 14.248 2.298 24.053 7.0474 9.9582 4.5961 15.933 11.49 5.9749 6.8941 9.1922 16.086 3.3705 9.039 4.4429 20.376 1.0724 11.184 1.0724 24.359l-0.1532 99.582h-29.415z"/>
                    </g>
                </g>
                <circle id="circle4377" cy="900.86" cx="2436.5" r="37.5" className={classes.orange}/>
            </g>
        </svg>
    )
}

export default withStyles(classes)(React.memo(Bonemash));
