import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

const classes = {
    root: {
        width: "100%"
    },
    white: {
        fill: "#fff"
    }
};

function LmxLogo({ classes, className }) {
    return (
        <svg viewBox="0 0 140 140" className={clsx([ classes.root, className ])}>
            <path className={classes.white} d="m140 118.48c0-30.987-83.125-4.7305-98.438-20.043-15.311-15.312 10.945-98.437-20.042-98.437-21.513 0-21.52 39.375-21.52 52.5 0 28.437 6.5625 72.19 10.938 76.56 4.375 4.38 48.125 10.94 76.562 10.94 13.125 0 52.5-0.009 52.5-21.519z"/>
            <circle className={classes.white} cx="52.501" transform="rotate(90)" cy="-87.507" r="35"/>
        </svg>
    )
}

export default withStyles(classes)(React.memo(LmxLogo));
