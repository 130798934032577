import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

const classes = {
    root: {
        width: "100%"
    },
    blue: {
        fill: "#64b5f6"
    },
    red: {
        fill: "#e57373"
    },
    green: {
        fill: "#81c784"
    },
    line: {
        stroke: "#000",
        strokeLinecap: "round",
        strokeWidth: 8,
        fill: "none"
    }
};

function SpinTheWheelLogo({ classes, className }) {
    return (
        <svg viewBox="0 0 512 512" className={clsx([ classes.root, className ])}>
            <g transform="translate(0 -540.36)">
                <circle r="256" cx="256" cy="796.36"/>
                <path className={classes.blue} d="m504 796.36a248 248 0 0 1 -124 214.77 248 248 0 0 1 -248 0l124-214.74z"/>
                <path className={classes.red} d="m504-796.36a248 248 0 0 1 -124 214.77 248 248 0 0 1 -248 0l124-214.77z" transform="scale(1,-1)"/>
                <path className={classes.green} transform="scale(1,-1)" d="m132-581.59a248 248 0 0 1 -124 -214.77 248 248 0 0 1 124 -214.74l124 214.77z"/>
                <path className={classes.line} d="m131.2 1015.2 124.21-217.46"/>
                <path className={classes.line} d="m131.98 581.42 124.02 214.94"/>
                <path className={classes.line} d="m256 796.36 248.2 0.16588"/>
            </g>
        </svg>
    )
}

export default withStyles(classes)(React.memo(SpinTheWheelLogo));
