import React from "react";
import Paper from "@material-ui/core/Paper";

import Left from "./Left";

function StaticLeft({ style, className, options, handleOptionsChange }) {
    return (
        <Paper square style={style} className={className}>
            <Left options={options} handleOptionsChange={handleOptionsChange}/>
        </Paper>
    )
}

export default React.memo(StaticLeft);
