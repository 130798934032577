import React, { useCallback } from "react";
import MuiTextField from "@material-ui/core/TextField";

import useEnterKeyPressHandler from "../hook/useEnterKeyPressHandler";

const inputLabelProps = { shrink: true };

function TextField({ inputRef, onEnterKeyPress, label, value, onChange, style, className, onBlur, autoFocus }) {
    const handleKeyPress = useEnterKeyPressHandler(onEnterKeyPress);

    const handleChange = useCallback(e => {
        onChange(e.target.value);
    }, [ onChange ]);

    return (
        <MuiTextField InputLabelProps={inputLabelProps}
                      inputRef={inputRef}
                      fullWidth
                      autoFocus={autoFocus}
                      style={style}
                      className={className}
                      onKeyPress={handleKeyPress}
                      label={label}
                      value={value}
                      onBlur={onBlur}
                      onChange={handleChange}/>
    )
}

export default React.memo(TextField);
