import { useEffect, useRef } from "react";
import useMountEffect from "./useMountEffect";

/**
 * @param callback should NOT change on every render
 */
function useResizeEffect(callback) {
    const savedCallback = useRef();
    useMountEffect(callback);
    useEffect(() => {
        if (callback !== savedCallback.current) {
            savedCallback.current = callback;
            window.addEventListener("resize", savedCallback.current);
            return () => { window.removeEventListener("resize", savedCallback.current) }
        }
    }, [ callback ]);
}

export default useResizeEffect;
